<div class="settings-container">
    <form [formGroup]="clusteringForm">
        <div class="inputs-row">
            <mat-form-field class="cluster-error-fields">
                <mat-select formControlName="datasetFieldId" placeholder="Field" class="cluster-field-dropdown">
                    <ng-container *ngFor="let field of dataset?.fields">
                        <mat-option *ngIf="field.baseType === DatasetFieldType.NUMBER && field.scope === DatasetFieldScope.INTERNAL" [value]="field.id">{{field.name}} </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="clusteringForm.get('datasetFieldId').errors?.required">
                    Field is required.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="inputs-row">
            <button mat-button mat-flat-button color="accent" (click)="enableHeatmap()">
                Apply
            </button>
            <button mat-button mat-flat-button color="primary" (click)="disableHeatmap()">
                Cancel
            </button>
        </div>
    </form>
</div>

