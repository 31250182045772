import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {filter} from 'minimatch';
import {DatapointFilterObject} from '../../model/datapoint/datapoint-filter-object';
import {Observable} from 'rxjs';
import {ComparisonProjectedDatapoint, ProjectedDatapoint} from '../../model/datapoint/projected-datapoint';
import {Datapoint} from '../../model/datapoint/datapoint';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';
import { DatapointProjection } from 'src/app/model/datapoint/projection/datapoint-projection';
import { DatapointFilterField } from 'src/app/model/datapoint/filter/datapoint-filter-field';

@Injectable({
    providedIn: 'root'
})
export class DatapointsService {

    constructor(private readonly http: HttpClient) {
    }

    getDatapointsByFilter(datapointFilterObject: DatapointFilterObject): Observable<ProjectedDatapoint[]> {
        return this.http.get<ProjectedDatapoint[]>(`/datapoints/dataset/${datapointFilterObject.filter.datasetID}`, {
            params: {
                filter: JSON.stringify(datapointFilterObject.filter),
                projection: JSON.stringify(datapointFilterObject.projection),
                limit: JSON.stringify(datapointFilterObject.limit),
                skip: JSON.stringify(datapointFilterObject.skip),
                sort: JSON.stringify(datapointFilterObject.sort)
            }
        });
    }

    getDatapointsByFilterForTableView(datapointFilterObject: DatapointFilterObject, limit?: number, skip?: number, fields?: DatapointFilterField[]): Observable<ProjectedDatapoint[]> {
        datapointFilterObject.filter.fields = fields !== undefined && fields.length > 0 ? datapointFilterObject.filter.fields.concat(fields) : datapointFilterObject.filter.fields;
        return this.http.get<ProjectedDatapoint[]>(`/datapoints/dataset/${datapointFilterObject.filter.datasetID}`, {
            params: {
                filter: JSON.stringify(datapointFilterObject.filter),
                projection: JSON.stringify(datapointFilterObject.projection),
                limit: limit == undefined ? JSON.stringify(datapointFilterObject.limit) : JSON.stringify(limit),
                skip: skip == undefined ? JSON.stringify(datapointFilterObject.skip) : JSON.stringify(skip),
                sort: JSON.stringify(datapointFilterObject.sort),
                isTableView: JSON.stringify(true)
            }
        });
    }

    getDatapoint(datapointID: string, datasetID: string): Observable<Datapoint> {
        return this.http.get<Datapoint>(`/datapoints/${datapointID}/dataset/${datasetID}`);
    }

    updateDatapoint(datasetId: string, datapointID: string, request: any): Observable<Datapoint> {
        return this.http.put<Datapoint>(`/datapoints/${datapointID}/dataset/${datasetId}`, request);
    }

    deleteDatapoints(datasetId: string, datapointIDs: string[]): Observable<string[]> {
        const options = {
            headers: new HttpHeaders({}),
            body: datapointIDs
            ,
        };
        return this.http.delete<string[]>(`/datapoints/dataset/${datasetId}`, options);
    }

    deleteDatapoint(datasetId: string, datapointID: string): Observable<string> {
        const options = {
            headers: new HttpHeaders({}),
        };
        return this.http.delete<string>(`/datapoints/${datapointID}/dataset/${datasetId}`, options);
    }

    deleteDatapointsByFilter(datasetId: string, datapointsFilter: DatapointFilter): Observable<number> {
        return this.http.delete<number>(`/datapoints/dataset/${datasetId}/filter`, {params: {filter: JSON.stringify(datapointsFilter)}});
    }

    getDatapointsCount(datapointsFilter: DatapointFilter, projection:DatapointProjection): Observable<any> {
        return this.http.get<number>(`/datapoints/aggregate/dataset/${datapointsFilter.datasetID}/count`, {
            params: {
                filter: JSON.stringify(datapointsFilter),
                projection : JSON.stringify(projection)
            }
        });
    }
    getOpenWeatherDatapointDetails(datapointId: String): Observable<any> {
        return this.http.get<number>(`/alerts/${datapointId}`);
    }

    getDatapointsByFilterForComparison(datapointFilterObject: DatapointFilterObject): Observable<ComparisonProjectedDatapoint[]> {
        return this.http.get<ComparisonProjectedDatapoint[]>(`/datapoints/dataset/comparison/${datapointFilterObject.filter.datasetID}`, {
            params: {
                filter: JSON.stringify(datapointFilterObject.filter),
                projection: JSON.stringify(datapointFilterObject.projection),
                limit: JSON.stringify(datapointFilterObject.limit),
                skip: JSON.stringify(datapointFilterObject.skip),
                sort: JSON.stringify(datapointFilterObject.sort)
            }
        });
    }
}

interface GetDatapointsRequest {
    filter: any;
    projection: any;
}
