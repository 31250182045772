import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifService } from '../../../core/notification/notif.service';
import { AuthService } from '../../auth.service';
import { Constants } from '../../../constants';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';
import { passwordValidator } from 'src/app/account/user-settings/validators';
import { checkStrength } from '../../register/register.component';


@Component({
    selector: 'app-forgot-password-confirm',
    templateUrl: './forgot-password-confirm.component.html',
    styleUrls: ['./forgot-password-confirm.component.scss']
})
export class ForgotPasswordConfirmationComponent {
    readonly forgotPasswordForm: UntypedFormGroup;
    tokenIsInvalid = false;
    isTokenValid: boolean;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private notifService: NotifService) {
        this.forgotPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            confirmPassword: ['',
                [Validators.required,
                Validators.minLength(3)
                ]]
        },
            {
                validator: [this.checkStrength, this.passwordMatchValidator]
            }
        );
    }

    ngOnInit(): void {
        let token = this.activatedRoute.snapshot.paramMap.get('token');
        this.authService.validateForgotPasswordToken(token).subscribe(
            success => {
                this.isTokenValid = success;
                if(!this.isTokenValid){
                this.notifService.success('Your new password has been activated');
                this.router.navigate(['/public/login']);}
            }, error => {
                if (error.status === Constants.BUSINESS_ERROR_CODE) {
                    this.tokenIsInvalid = true;
                }
            }
        );
    }


    passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const password = control.get('password');
        const confirmPassword = control.get('confirmPassword');

        return password && confirmPassword && password.value !== confirmPassword.value
            ? { 'passwordsNotMatching': true }
            : null;
    }

    checkStrength(control: AbstractControl): { [key: string]: boolean } | null {
        const password = control.get('password');
        const hasNumber = /\d/.test(password.value);
        const hasUpper = /[A-Z]/.test(password.value);
        const hasLower = /[a-z]/.test(password.value);
        const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password.value);
        return password && hasNumber && hasUpper && hasLower && hasSpecial && password.value.length >= 8 ? {'weak':false} : { 'weak': true };
    }


    onSubmit(): void {
        let token = this.activatedRoute.snapshot.paramMap.get('token');
        let form = this.forgotPasswordForm.value;
        if (token && this.forgotPasswordForm.valid) {
            this.authService.confirmForgotPassword(token, form.password, form.confirmPassword)
                .subscribe(
                    success => {
                        this.notifService.success('Your new password has been activated');
                        this.router.navigate(['/public/login']);
                    }, error => {
                        if (error.status === Constants.BUSINESS_ERROR_CODE) {
                            this.tokenIsInvalid = true;
                        }
                    }
                );
        }
        else {
            this.notifService.error('Please fill the form correctly');
            this.forgotPasswordForm.markAllAsTouched();
        }
    }


}
