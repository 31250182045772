<div class="public-content">
    <div class="public-text">
        <img src="../../../assets/images/logo-icon-dark.svg" style="width: 300px; height: auto;" alt="logo">
    </div>
    <div class="public-form" style="display: flex; flex-direction: column; align-items: center;">
        <form [formGroup]="registerForm">
            <mat-form-field [floatLabel]="'never'" color="#fff">
                <mat-label>Name</mat-label>
                <input matInput autocomplete="off" [errorStateMatcher]="matcher" required
                       formControlName="name">
                <mat-error *ngIf="registerForm.controls.name.hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Password</mat-label>
                <input matInput autocomplete="off" [errorStateMatcher]="matcher" required
                       formControlName="password" type="password">
                       <mat-error *ngIf="registerForm.controls.password.hasError('required')">
                        Password is required
                    </mat-error>
                    
            </mat-form-field>
            <mat-error *ngIf="registerForm.controls.password.hasError('weak')" style="font-size: smaller;">
                Your Password must contain : <br>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                                  
                          &nbsp;Atleast 8 or more characters,
                    </div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                      &nbsp;Atleast one number,</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    &nbsp;Atleast one uppercase character,</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                          &nbsp;Atleast one lowercase character</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                          &nbsp;Atleast one special character</div>
            </mat-error>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Confirm Password</mat-label>
                <input matInput autocomplete="off" [errorStateMatcher]="matcher" required
                       formControlName="confirmPassword" type="password">
                <mat-error *ngIf="registerForm.controls.confirmPassword.hasError('required')">
                    Confirm Password is required
                </mat-error>
                <mat-error *ngIf="registerForm.controls.confirmPassword.hasError('mustMatch')">
                    Passwords must match
                </mat-error>
            </mat-form-field>
            <button mat-stroked-button color="primary" [disabled]="registerForm.invalid || registerForm.controls.password.hasError('weak')" (click)="onSubmit()">Register</button>
        </form>
    </div>
</div>
