<div class="accumulations-thematic-map">
    <div>
        <div class="thematic-map-menus-container-no-bg">
            <div class="thematic-map-menus">
                
                <div class="thematic-map-mennu-contanier" style="display: flex; justify-content: space-evenly;">
                    <div class="thematic-map-menu" >
                        <span>Values</span>
                        <button [matMenuTriggerFor]="valueMenu">
                            <i class="fal fa-edit" style="font-size: 14px;"></i>
                        </button>
                        <mat-menu #valueMenu="matMenu">
                            <div class="thematic-map-menu-options">
                                <div *ngFor="let item of dataset?.fields">
                                    <div *ngIf="!item.isGenerated && item.baseType === datasetFieldType.NUMBER"
                                        (click)="$event.stopPropagation()">
                                        <mat-checkbox (change)="selectThematicMapMetric(item, $event)"
                                            [checked]="item.selectedForTM">
                                            <span class="check-box-style" [matTooltip]=item.name matTooltipPosition="right">{{item.name}}</span>
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                          </mat-menu>
                    </div>
                    <ng-container *ngIf="isValueMenuOpen">
                        <div class="thematic-map-menu-options">
                            <div *ngFor="let item of dataset?.fields">
                                <div *ngIf="!item.isGenerated && item.baseType === datasetFieldType.NUMBER"
                                    (click)="$event.stopPropagation()">
                                    <mat-checkbox (change)="selectThematicMapMetric(item, $event)"
                                        [checked]="item.selectedForTM">
                                        <span class="check-box-style" [matTooltip]=item.name matTooltipPosition="right">{{item.name}}</span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                
                <div class="thematic-map-mennu-contanier">
                    <div class="thematic-map-menu">
                        <span>Formulas</span>
                        <button [matMenuTriggerFor]="formulasMenu">
                            <i class="fal fa-edit" style="font-size: 14px;"></i>
                        </button>
                    </div>
                    <mat-menu #formulasMenu="matMenu">
                        <div class="thematic-map-menu-options">
                            <div *ngFor="let item of formulas">
                                <mat-checkbox (change)="selectThematicMapFormula(item, $event)"
                                    [checked]="item.selectedForTM">
                                    <span class="check-box-style" [matTooltip]=item.name matTooltipPosition="right">{{item.name}}</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-menu>    
                </div>

                <div class="thematic-map-mennu-contanier">
                    <div class="thematic-map-menu">
                        <span>Grouping</span>
                        <button [matMenuTriggerFor]="groupingMenu">
                            <i class="fal fa-edit" style="font-size: 14px;"></i>
                        </button>
                    </div>
                    <mat-menu #groupingMenu="matMenu">
                        <div class="thematic-map-menu-options">
                            <ng-container *ngFor="let item of groupingOptions">
                                <mat-checkbox (change)="selectThematicMapGrouping(item)"
                                    [checked]="item === accumulationsSerivce.thematicMapGrouping"
                                    [disabled]="item === accumulationsSerivce.thematicMapGrouping">
                                    <span class="check-box-style" [matTooltip]="item | titlecase" matTooltipPosition="right">{{ item | titlecase}}</span>
                                </mat-checkbox>
                            </ng-container>
                        </div>
                    </mat-menu>
                </div>
                
                <div class="thematic-map-menu">
                    <span>Analytics</span>
                    <button (click)="isAnalyticsMenuOpen = !isAnalyticsMenuOpen; openThematicMapAnalyticsPanel(isAnalyticsMenuOpen)">
                        <mat-icon *ngIf="!isAnalyticsMenuOpen">expand_more</mat-icon>
                        <mat-icon *ngIf="isAnalyticsMenuOpen">expand_less</mat-icon>
                    </button>
                </div>

            </div>
            <div class="thematic-map-actions">
                <button mat-button mat-flat-button color="accent" (click)="activateThematicMap()">Apply</button>
                <button mat-button mat-flat-button color="primary" (click)="deactivateThematicMap()">Cancel</button>
            </div>
        </div>
    </div>

    <div class="thematic-map-menus-container" style="margin-top: 30px;">
        
        <div *ngIf="isThematicMapActivated" class="thematic-map-data">
            <div class="thematic-map-data-component" *ngIf="thematicMapMetric">
                <span class="component-label">Colorized by {{thematicMapMetric.name}}: </span>
                <span class="component-value" style="color:  #0032ef;">
                    {{thematicMapTotal | commaFormat}}
                </span>
            </div>
            <div class="space-between-menus"></div>
            <div class="thematic-map-data-component" *ngIf="thematicMapFormula">
                <span class="component-label">Colorized by {{thematicMapFormula.name}}:</span>
                <span class="component-value">
                    {{thematicMapTotal | commaFormat}}
                </span>
            </div>
            <div class="thematic-map-data-component">
                <span class="component-label">Count:</span>
                <span class="component-value">
                    {{thematicMapCount | commaFormat}}
                </span>
            </div>
            <div class="space-between-menus"></div>
            <ng-container *ngFor="let field of datasetFloatingFields">
                <div class="thematic-map-data-component">
                    <span class="component-label">{{ field.name }}:</span>
                    <span class="component-value">
                        {{floatingFieldsValuesByFields?.get(field) | commaFormat }}
                    </span>
                </div>
                <div class="space-between-menus"></div>
            </ng-container>
        </div>
    </div>

</div>
