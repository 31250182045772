// tslint:disable:max-file-line-count
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { DatapointsPageStateService } from "../../datapoints-page-state.service";
import { Dataset } from "../../../../model/dataset/dataset";
import { DatasetField } from "../../../../model/dataset/field/dataset-field";
import { DatapointFilter } from "../../../../model/datapoint/filter/datapoint-filter";
import { DatapointProjection } from "../../../../model/datapoint/projection/datapoint-projection";
import { ReportRow } from "../../../../model/datapoint/report/count/report-row";
import { ChartDataSets, ChartLegendLabelItem } from "chart.js";
import { DatapointsAggregateService } from "../../../../data-access-layer/datapoints/datapoints-aggregate.service";
import { DatasetGeometryType } from "../../../../model/dataset/dataset-geometry-type";
import { ReportRequest } from "../../../../model/datapoint/report/report-request";
import { AggregateGroupRequest } from "../../../../model/datapoint/report/aggregate-group-request";
import { ReportResultResponse } from "../../../../model/datapoint/report/report-result-response";
import { DatasetUtils } from "../../../../core/utils/dataset-utils";
import { Sort } from "@angular/material/sort";
import { ReportResultGroupResponse } from "../../../../model/datapoint/report/report-result-group-response";
import { ChartInfo } from "../../../../model/datapoint/report/chart-info";
import { ColorUtils } from "../../../../core/utils/color-utils";
import { ReportDisplayType } from "../../../../model/analytics/report-display-type";
import { DatasetFieldType } from "../../../../model/dataset/dataset-field-type";
import { ChartDisplayType } from "../../../../model/analytics/chart-display-type";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DatapointsFilterService } from "../../datapoints-filter.service";
import { Subscription } from "rxjs";
import { UserStateService } from "../../../../auth/user-state-service";
import { DatasetFieldSpecificType } from "../../../../model/dataset/dataset-field-specific.type";
import {
    TableColumn,
    TableColumnType,
    TableColumnAlignment,
} from "../../../../model/upload/table/table-column";
import { TableCell } from "../../../../model/upload/table/table-cell";
import { TableRow } from "../../../../model/upload/table/table-row";
import { DownloadReportItem } from "../../../../model/download/item/download-report-item";
import { DownloadReportTableRequest } from "../../../../model/download/download-report-table-request";
import { ReportComponent } from "../report.component";
import {
    DownloadReportChartRequest,
    ValueKey,
} from "../../../../model/download/download-report-chart-request";
import { DownloadItemReportType } from "../../../../model/download/item/download-item-report-type";
import { DownloadReportChartValueRequest } from "../../../../model/download/download-report-chart-value-request";
import { OverlaysConstants } from "../../../../overlays/overlays.constants";
import { OverlaysService } from "../../../../data-access-layer/global-overlays/overlays.service";
import { WorkspaceItem } from "../../../../model/workspace/workspace-item";
import { DatapointAggregateFieldType } from "../../../../model/datapoint/report/datapoint-aggregate-field-type";
import { isUndefined } from "src/app/core/utils/util-master";
import { StringUtils } from "src/app/core/utils/string-utils";
import { ReportType } from "src/app/model/analytics/report-type";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { Datapoints } from "../../datapoints";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";
import { GroupWithOverlaysTreeNode } from "src/app/model/overlay/group/group-with-overlays-tree-node";
import { DatasetType } from "src/app/model/dataset/dataset-type";
import { AnayticsConstants } from "src/app/constants";
import { RandomUtils } from "src/app/core/utils/random-utils";
import { AnalyticsUtils } from "src/app/core/analytics/analytics-utils";
import { ObjectUtils } from "src/app/core/utils/object-utils";
import { TreeNode, TreeStructure } from "src/app/model/menu/tree-structure";

@Component({
    selector: "map-aggregate-report",
    templateUrl: "./aggregate-report.component.html",
    styleUrls: ["./aggregate-report.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregateReportComponent
    implements OnInit, OnDestroy, ReportComponent
{
    @Input() uuid: string;
    @Output() closed = new EventEmitter();
    @Output() saveWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveAsWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveSorting = new EventEmitter();

    _formulas: WorkspaceItem[] = [];
    @Input() set formulas(formulas: WorkspaceItem[]) {
        this._formulas = formulas;
    }

    @Input() fromAnalytics: boolean = true;

    _dataset: Dataset;
    _nriFields: any;
    _climateOverlays: any;

    @Input() set dataset(dataset: Dataset) {
        this._dataset = JSON.parse(JSON.stringify(dataset));
    }

    @Input() set nriFields(nriFields: any) {
        this._nriFields = JSON.parse(JSON.stringify(nriFields));
    }

    @Input() dashboardWorkspaceItems: WorkspaceItem[];
    @Input() isDashboardCall: boolean;

    get climateOverlays(): any {
        return this.climateOverlays;
    }
    @Input() set climateOverlays(climateOverlays: any) {
        this._climateOverlays = JSON.parse(JSON.stringify(climateOverlays));
    }

    private readonly subscriptions: Subscription = new Subscription();
    private LIMIT: number = 247;

    selectedAggregateField: DatasetField; // selected locations value
    selectedFormula: WorkspaceItem;
    selectedAggregateName: string;
    selectedBreakdownFieldsByDataset: Map<string, DatasetField[]> = new Map(); // dataset id, selected values for breakdown
    selectedBreakdownFieldsCount = 0;
    reportName: string;
    datasetFields: DatasetField[];
    datasetsToChooseFrom: Dataset[];
    filterAccountDatasets: Dataset[];
    reportType: string = ReportType.AGGREGATE;
    reportSubType: ReportDisplayType = ReportDisplayType.TABLE;
    chartDisplayType: ChartDisplayType = ChartDisplayType.AGGREGATE;
    dataIsReady = false;

    datapointFilter: DatapointFilter;
    datapointProjection: DatapointProjection;
    tessadataFields: {
        nriFields: DatasetField[];
        externalFields: DatasetField[];
        tensorflightFields: DatasetField[];
        munichreFields: DatasetField[];
    };
    tessadataGroupedFields: any[];

    tableColumnsExcludedOverlayGroups: number[];

    /** TABLE  */
    dynamicColumns: Map<string, string>; // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
    columnsToDisplay: string[];
    totalCount: number;
    totalAggregate: number;
    reportData: ReportRow[];
    downloadReportData: ReportRow[];

    /** CHART  */
    chartDatasets: ChartDataSets[];
    chartLabels: string[];
    chartColors: any[];
    chartOptions: any = {
        responsive: true,
        legend: {
            labels: {
                generateLabels: (chart) => {
                    let legendItems: ChartLegendLabelItem[] = [];

                    chart.data.datasets.forEach((dataset) => {
                        legendItems.push({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor[0], // because all values os the secondary dataset have the same color
                        });
                    });
                    if (legendItems.length <= 10) {
                        // not to overload the page
                        return legendItems;
                    } else {
                        return [];
                    }
                },
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var value =
                        data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                        ];
                    var name = data.labels[tooltipItem.index];
                    const roundedUpValue =
                        typeof value === "number" ? value.toFixed(2) : value;
                    return (
                        name +
                        ": " +
                        StringUtils.numberWithCommas(roundedUpValue)
                    );
                },
            }, // end callbacks:
        },
    };

    readonly COUNT_COLUMN_ID = "count";
    readonly AGGREGATE_COLUMN_ID = "aggregate";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";
    readonly AVERAGE_COLUMN_ID = "average";

    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    sort: Sort = {
        active: "",
        direction: "",
    };

    aggregateTreeStrcuture = new TreeStructureUtils();
    aggregateTreeControl = this.aggregateTreeStrcuture.getTreeControl();
    aggregateDataSource = this.aggregateTreeStrcuture.getDataSource();

    breakdownTreeStrcuture = new TreeStructureUtils();
    breakdownTreeControl = this.breakdownTreeStrcuture.getTreeControl();
    breakdownDataSource = this.breakdownTreeStrcuture.getDataSource();
    datapointObject = new Datapoints();
    globalOverlays: GroupWithOverlaysTreeNode[] = [];

    // Comparison declarations
    @Input() isComparisonModeActivated: boolean;
    @Input() set groups(groups: any) {
        this._groups = JSON.parse(JSON.stringify(groups));
        this._compareGroups = ObjectUtils.clone(this._groups);
        this._withGroups = ObjectUtils.clone(this._groups);
    }
    ulClassName = 'compareGroups';
    @Input() set groupsStrcuture(groupsStrcuture: TreeStructure[]) {
        this._groupsStrcuture = groupsStrcuture;
        this._compareGroupsStrcuture = ObjectUtils.clone(this._groupsStrcuture);
        this._withGroupsStrcuture = ObjectUtils.clone(this._groupsStrcuture);;
    }
    treeControlGroup = this.breakdownTreeStrcuture.getTreeControl();
    _groups = [];
    _compareGroups = [];
    _withGroups = [];
    _groupsStrcuture: any[] = [];
    _compareGroupsStrcuture: any[] = [];
    _withGroupsStrcuture: any[] = [];
    isCompareDropdownOpen: boolean = true;
    isCompareWithDropdownOpen: boolean = true;
    totalCountPrimaryGroup: number;
    totalCountSecondaryGroup: number;
    totalAggregatePrimaryGroup: number;
    totalAggregateSecondaryGroup: number;
    get AnayticsConstants() {
        return AnayticsConstants;
    }
    get AnalyticsUtils() {
        return AnalyticsUtils;
    }
    get RandomUtils() {
        return RandomUtils;
    }
    columnsToDisplayForComparison: string[];
    activeGroups = [];

    readonly PRIMARY_AGGREGATE_COLUMN_ID = "primaryAggregate";
    readonly SECONDARY_AGGREGATE_COLUMN_ID = "secondaryAggregate";
    readonly COMPARE_AMOUNT_COLUMN_ID = "compareAmount";
    readonly COMPARE_COLUMN_ID = "comparePercentage";

    constructor(
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly aggregateService: DatapointsAggregateService,
        private readonly userStateService: UserStateService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly overlayService: OverlaysService
    ) {}

    ngOnInit() {
        if (
            [MaptycsApplication.CLAIMS, MaptycsApplication.POLICIES].includes(
                this._dataset.application
            )
        ) {
            this._climateOverlays = this.datapointObject.filterAndDelete(
                this._climateOverlays,
                { isTextTypeCheck: true }
            );
        }

        if (this._climateOverlays?.length) {
            this.globalOverlays = this._climateOverlays;
            this.globalOverlays = this.setFieldsSelected(this.globalOverlays);
        }

        this.resetNRISelectedFlag();
        let datasetsToGroupBy = this.datapointsPageStateService
            .getLinkedAccountDatasets()
            .concat(
                this.datapointsPageStateService
                    .getLinkedAccountOverlays()
                    .filter(
                        (overlay) =>
                            overlay.geometryType === DatasetGeometryType.COMPLEX
                    )
            );

        if (
            !datasetsToGroupBy.find(
                (dataset) => dataset.id === this._dataset.id
            )
        ) {
            datasetsToGroupBy.splice(0, 0, this._dataset);
        }

        this.subscriptions.add(
            this.overlayService
                .getOverlayGroupsByTag(
                    OverlaysConstants.HIDE_GROUP_FROM_TABLE_COLUMNS_TAG
                )
                .subscribe(
                    (overlayGroups) =>
                        (this.tableColumnsExcludedOverlayGroups =
                            overlayGroups.map((group) => group.id))
                )
        );

        this._formulas = JSON.parse(JSON.stringify(this._formulas));
        this.datapointFilter = this.datapointsFilterService.getActiveFilter();
        this.tessadataFields = JSON.parse(
            JSON.stringify(
                this.datapointsPageStateService.getActiveTessadataFields()
            )
        );
        this.tessadataFields.externalFields.forEach(
            (field) => (field.selected = false)
        );
        this.tessadataFields.nriFields = this.sortFields(
            this.tessadataFields.nriFields
        );
        this.tessadataFields.tensorflightFields = this.sortFields(
            this.tessadataFields.tensorflightFields
        );
        this.tessadataFields.munichreFields = this.sortFields(
            this.tessadataFields.munichreFields
        );
        if (
            this.tessadataFields.externalFields &&
            this.tessadataFields.externalFields.length > 0
        ) {
            let externalDataset = this.sortFields(
                this.tessadataFields.externalFields
            );
            this.tessadataGroupedFields = externalDataset.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            for (const key in this.tessadataGroupedFields) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.tessadataGroupedFields,
                        key
                    )
                ) {
                    this.tessadataGroupedFields[key] = this.sortFields(
                        this.tessadataGroupedFields[key]
                    );
                }
            }
        }
        if (
            this.tessadataFields.tensorflightFields &&
            this.tessadataFields.tensorflightFields.length > 0
        ) {
            let tensorflightDataset = this.sortFields(
                this.tessadataFields.tensorflightFields
            );
            let tensorflightGroupFields = tensorflightDataset.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            for (const key in tensorflightGroupFields) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        tensorflightGroupFields,
                        key
                    )
                ) {
                    tensorflightGroupFields[key] = this.sortFields(
                        tensorflightGroupFields[key]
                    );
                }
            }
            this.tessadataGroupedFields =
                !isUndefined(this.tessadataGroupedFields) &&
                Object.keys(this.tessadataGroupedFields).length
                    ? {
                          ...this.tessadataGroupedFields,
                          ...tensorflightGroupFields,
                      }
                    : tensorflightGroupFields;
        }
        if (
            this.tessadataFields.munichreFields &&
            this.tessadataFields.munichreFields.length > 0
        ) {
            let munichreDataset = this.sortFields(
                this.tessadataFields.munichreFields
            );
            let munichreGroupFields = munichreDataset.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [
                        ...(groups[item.tags[0]] || [])
                            .map(group => group[item.tags[1]] ? {
                                ...group, [item.tags[1]]:
                                    [...group[item.tags[1]], item]
                            } : group
                            ),
                        ...(groups[item.tags[0]]?.some(group => group[item.tags[1]]) ? [] : [{ [item.tags[1]]: [item] }])
                    ]
                }),
                []
            );
            for (const key in munichreGroupFields) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        munichreGroupFields,
                        key
                    )
                ) {
                    munichreGroupFields[key] = this.sortFields(
                        munichreGroupFields[key]
                    );
                }
            }
            this.tessadataGroupedFields =
                !isUndefined(this.tessadataGroupedFields) &&
                Object.keys(this.tessadataGroupedFields).length
                    ? { ...this.tessadataGroupedFields, ...munichreGroupFields }
                    : munichreGroupFields;
        }
        this.datasetsToChooseFrom = JSON.parse(
            JSON.stringify(datasetsToGroupBy)
        );
        // this.filterAccountDatasets = this.datapointsPageStateService.getFilterAccountDatasetsCloned();
        // if(this.filterAccountDatasets){
        //     this.filterAccountDatasets.map((dataSet) => {
        //       if(dataSet.fields.length > 0){
        //          dataSet.fields.map((field) => { field.selected = false;  })
        //       }
        //     });
        // }
        this.datasetFields = this.sortFields(
            JSON.parse(JSON.stringify(this._dataset.fields))
        );

        this.subscriptions.add(
            this.datapointsFilterService
                .onFilterChange()
                .subscribe((newFilter) => {
                    this.datapointFilter = newFilter;
                    if (this.dataIsReady) {
                        this.generateReportData();
                    }
                })
        );
        let prepareNumberTypeDataset = this.datapointObject.prepareDataset(
            [this._dataset],
            this._dataset,
            { type: DatasetFieldSpecificType.NUMBER_FIELD }
        );
        if (prepareNumberTypeDataset[0].children.length > 0) {
            let preparedFormulas = this.datapointObject.prepareFormulas(
                this._formulas
            );
            this.aggregateDataSource.data =
                preparedFormulas.length > 0
                    ? [...prepareNumberTypeDataset, ...preparedFormulas]
                    : [...prepareNumberTypeDataset];
        } else {
            this.aggregateDataSource.data = [
                ...this.datapointObject.prepareFormulas(this._formulas),
            ];
        }
        let tessadataFieldsByDataset = {};
        tessadataFieldsByDataset[this._dataset.id] = { nriFields: [] };
        (tessadataFieldsByDataset[this._dataset.id].nriFields = this),
            this.nriFields;
        const datasetArray = !this.tableColumnsExcludedOverlayGroups?.includes(
            this._dataset.overlayGroupId
        )
            ? this.datapointObject.prepareDataset(
                  [this._dataset],
                  this._dataset
              )
            : [];
        if (datasetArray.length <= 0) {
            this.breakdownDataSource.data = [
                ...this.datapointObject.prepareTesadata(
                    this.tessadataGroupedFields,
                    this._dataset
                ),
                ...this.datapointObject.prepareNRIFields(
                    [this._dataset],
                    tessadataFieldsByDataset,
                    true,
                    this._dataset
                ),
                ...this.datapointObject.filterAndDelete(
                    [
                        ...this.datapointObject.prepareClimateData(
                            this.globalOverlays,
                            false,
                            false,
                            false
                        ),
                    ],
                    {
                        isTextTypeCheck: true,
                        geometryTypes: [DatasetGeometryType.COMPLEX],
                    }
                ),
            ];
        } else {
            this.breakdownDataSource.data = [
                ...datasetArray,
                ...this.datapointObject.prepareTesadata(
                    this.tessadataGroupedFields,
                    this._dataset
                ),
                ...this.datapointObject.prepareNRIFields(
                    [this._dataset],
                    tessadataFieldsByDataset,
                    true,
                    this._dataset
                ),
                ...this.datapointObject.filterAndDelete(
                    [
                        ...this.datapointObject.prepareClimateData(
                            this.globalOverlays,
                            false,
                            false,
                            false
                        ),
                    ],
                    {
                        isTextTypeCheck: true,
                        geometryTypes: [DatasetGeometryType.COMPLEX],
                    }
                ),
            ];
        }
        this.breakdownDataSource.data = this.datapointObject.filterAndDelete(
            this.breakdownDataSource.data,
            { isTextTypeCheck: true }
        );
    }

    setSelectedAggregateField(field: DatasetField) {
        let selectedField = this.datasetFields.find((f) => f.id === field.id);
        this.selectedAggregateField = selectedField;
        selectedField.selected = true;
        this.datapointObject.markSelected(
            this.aggregateDataSource.data,
            selectedField.id
        );
    }

    setSelectedFormula(formula: WorkspaceItem) {
        let selectedFormula = this._formulas.find((f) => f.id === formula.id);
        this.selectedFormula = selectedFormula;
        selectedFormula.selected = true;
        this.datapointObject.markSelected(
            this.aggregateDataSource.data,
            selectedFormula.id
        );
    }

    getSelectedAggregateField(): DatasetField {
        return this.selectedAggregateField;
    }

    getSelectedFormula(): WorkspaceItem {
        return this.selectedFormula;
    }

    getSort() {
        return this.sort;
    }

    setSelectedBreakdownFieldsByDataset(
        selectedFieldsByDataset: Map<string, DatasetField[]>
    ) {
        // the following is necessary for checking the selected field in the breakdown dropdown
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            this.selectedBreakdownFieldsCount += fields.length;
            let selectedFields: DatasetField[] = [];
            let selectedDataset = this.datasetsToChooseFrom.find(
                (d) => d.id === datasetId
            );
            if (selectedDataset) {
                selectedDataset.fields.forEach((selectionField) => {
                    let fieldIsSelected = fields.find(
                        (f) => f.id === selectionField.id
                    );
                    if (fieldIsSelected) {
                        selectionField.selected = true;
                        this.datapointObject.markSelected(
                            this.breakdownDataSource.data,
                            selectionField.id
                        );
                        selectedFields.push(selectionField);
                    }
                });
                this.selectedBreakdownFieldsByDataset.set(
                    selectedDataset.id,
                    selectedFields
                );
            }
        });
    }

    setDatapointFilter(filter: DatapointFilter) {
        this.datapointFilter = filter;
    }

    includeThematicMapDatasets(includeThematicMapDatasets: boolean) {
        if (includeThematicMapDatasets) {
            let thematicDatasets: Dataset[] =
                this.datapointsPageStateService.accountOverlays.filter(
                    (dataset) =>
                        dataset.thematicMapSettings &&
                        dataset.thematicMapSettings.isThematicMapDataset
                );
            this.datasetsToChooseFrom.push(...thematicDatasets);
        } else {
            this.datasetsToChooseFrom = this.datasetsToChooseFrom.filter(
                (dataset) =>
                    !dataset.thematicMapSettings ||
                    !dataset.thematicMapSettings.isThematicMapDataset
            );
        }
    }

    getValidDatasetId(dataset, overlay) {
        const validDataset = overlay || dataset;
        return validDataset.id;
    }

    markFieldDisable(node) {
        let totalSelectedFields = 0;
        for (const [_key, value] of this.selectedBreakdownFieldsByDataset) {
            totalSelectedFields += value.length;
        }

        return (
            !node.selected &&
            totalSelectedFields >= this.fieldSelectLimitCount()
        );
    }

    fieldSelectLimitCount() {
        return this.isComparisonModeActivated
            ? AnayticsConstants.MAX_FIELD_SELECT_LIMIT_COMPARISON
            : AnayticsConstants.MAX_FIELD_SELECT_LIMIT;
    }

    onFieldsMenuClick(
        fieldSelected: boolean,
        dataset: Dataset,
        field: DatasetField,
        overlay?: Dataset
    ) {
        const validDataset = overlay || dataset;
        let datasetFields =
            this.selectedBreakdownFieldsByDataset.get(validDataset.id) || [];
        if (fieldSelected) {
            datasetFields.push(field);
            field.selected = true;
            this.selectedBreakdownFieldsCount++;
        } else {
            datasetFields = datasetFields.filter((f) => f.id !== field.id);
            field.selected = false;
            this.selectedBreakdownFieldsCount--;
        }

        if (datasetFields.length === 0) {
            this.selectedBreakdownFieldsByDataset.delete(validDataset.id);
        } else {
            this.selectedBreakdownFieldsByDataset.set(
                validDataset.id,
                datasetFields
            );
        }
    }

    generateReport() {
        if (
            this.selectedBreakdownFieldsByDataset.size > 0 &&
            (this.selectedAggregateField || this.selectedFormula)
        ) {
            // this.setReportName();
            this.generateReportData();
        } else {
            this.dataIsReady = false;
        }
    }

    generateDatapointFilter() {
        const currentFilter = isUndefined(this.datapointFilter)
            ? { datasetID: this._dataset.id }
            : this.datapointFilter;

        if (this.isComparisonModeActivated) {
            currentFilter.groups = this.getSelectedGroups().map((group) => {
                return group.id;
            });
        }
        return currentFilter;
    }

    generateReportData() {
        let reportRequest = this.createReportRequest();
        this.populateTableColumnsList();
        this.prepareProjection();
        this.populateAggregateFieldColumnName();
        const datapointFilter = this.generateDatapointFilter();

        if (!this.isComparisonModeActivated) {
            this.generateAnalytic(reportRequest, datapointFilter);
        } else if (
            datapointFilter.groups.length ===
            AnayticsConstants.MAX_GROUP_SELECT_LIMIT
        ) {
            this.generateComparisonAnalytic(reportRequest, datapointFilter);
        }
    }

    generateAnalytic(reportRequest, datapointFilters) {
        this.subscriptions.add(
            this.aggregateService
                .getDatapointsReport(
                    this._dataset.id,
                    datapointFilters,
                    reportRequest,
                    this.datapointProjection
                )
                .subscribe((success) => {
                    this.computeTotalCountAndAggregate(success.groupResults);
                    this.convertDataToTableFormat(success.groupResults);
                    this.populateTableColumnsList();
                    if (this.sort && !isUndefined(this.sort)) {
                        this.sortData(this.sort);
                    }
                    this.populateChartData();

                    this.dataIsReady = true;
                    this.changeDetector.detectChanges();
                })
        );
    }

    generateComparisonAnalytic(reportRequest, datapointFilter) {
        this.subscriptions.add(
            this.aggregateService
                .getComparisonAnalyticsData(
                    this._dataset.id,
                    datapointFilter,
                    reportRequest,
                    this.datapointProjection
                )
                .subscribe((success) => {
                    this.activeGroups = this.getSelectedGroups();
                    this.computeTotalCountAndAggregateForComparison(
                        success.groupResults
                    );
                    this.convertDataToTableFormatForComparison(
                        success.groupResults
                    );
                    this.populateTableColumnsListForComparison();
                    if (this.sort && !isUndefined(this.sort)) {
                        this.sortData(this.sort);
                    }
                    this.populateChartData();

                    this.dataIsReady = true;
                    this.changeDetector.detectChanges();
                })
        );
    }

    private createReportRequest(): ReportRequest {
        let groups: AggregateGroupRequest[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                groups.push({
                    datasetID: datasetId,
                    fieldID: field.id,
                });
            });
        });

        let aggregateFieldType;
        let aggregateFieldCodes;
        let aggregateFieldFormula;

        if (this.selectedFormula) {
            aggregateFieldType = DatapointAggregateFieldType.FORMULA;
            aggregateFieldFormula = this.selectedFormula.data;
        } else {
            aggregateFieldType = DatapointAggregateFieldType.FIELD;
            let formulaId = `${this._dataset.id}.${this.selectedAggregateField.id}`;
            aggregateFieldCodes = [
                { aggregateFieldCode: `VAR_${formulaId}`, id: formulaId },
            ];
        }

        return {
            datasetID: this._dataset.id,
            groups: groups,
            aggregateFieldCodes: aggregateFieldCodes,
            aggregateFieldType: aggregateFieldType,
            aggregateFieldFormulaJson: aggregateFieldFormula,
        };
    }

    private populateTableColumnsList() {
        this.dynamicColumns = new Map();
        this.columnsToDisplay = [];

        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                this.dynamicColumns.set(key, field.name);
                this.columnsToDisplay.push(key);
            });
        });

        this.columnsToDisplay.push(this.AGGREGATE_COLUMN_ID);
        this.columnsToDisplay.push(this.PERCENTAGE_COLUMN_ID);
        this.columnsToDisplay.push(this.COUNT_COLUMN_ID);
        this.columnsToDisplay.push(this.AVERAGE_COLUMN_ID);
    }

    private computeTotalCountAndAggregate(
        groupResults: ReportResultResponse[]
    ) {
        this.totalCount = 0;
        this.totalAggregate = 0;
        groupResults.forEach((groupResult) => {
            this.totalCount += groupResult.values[0].count;
            this.totalAggregate += groupResult.values[0].result;
        });

        this.totalAggregate =
            RandomUtils.roundUp(this.totalAggregate * 100) / 100;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    get ChartDisplayType() {
        return ChartDisplayType;
    }

    getFieldType(field: DatasetField) {
        return DatasetUtils.getDatasetFieldType(field);
    }

    setDisplayType(reportSubType: ReportDisplayType) {
        if (this.selectedBreakdownFieldsByDataset.size > 0) {
            this.reportSubType = reportSubType;
        }
        this.chartOptions.scales =
            reportSubType === ReportDisplayType.BAR_CHART
                ? {
                      yAxes: [
                          {
                              ticks: {
                                  callback: (value, index, values) => {
                                      return StringUtils.numberWithCommas(
                                          value
                                      );
                                  },
                              },
                          },
                      ],
                  }
                : null;

        this.chartOptions.tooltips = {
            callbacks: {
                label: (tooltipItem, data) => {
                    const value =
                        data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                        ];
                    const name = data.labels[tooltipItem.index];
                    const roundedUpValue =
                        typeof value === "number" ? value.toFixed(2) : value;
                    let generatedTooltip = `${name}:${StringUtils.numberWithCommas(
                        roundedUpValue
                    )}`;

                    if (this.isComparisonModeActivated) {
                        generatedTooltip = `${
                            data.datasets[tooltipItem.datasetIndex].label
                        } - ${name}:${StringUtils.numberWithCommas(
                            roundedUpValue
                        )}`;
                    }

                    return generatedTooltip;
                },
            },
        };

        this.populateChartData();
    }

    sortData(sort: Sort, isSortCall: boolean = false) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        if (isUndefined(this.sort)) {
            this.sort = {
                active: "",
                direction: "",
            };
        }
        this.sort.active = fieldId;
        this.sort.direction = sort.direction;

        let sortedData = this.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.COUNT_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.count,
                        b.count,
                        isAsc
                    );
                case this.PERCENTAGE_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.percentage,
                        b.percentage,
                        isAsc
                    );
                case this.AGGREGATE_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.aggregate,
                        b.aggregate,
                        isAsc
                    );
                case this.AVERAGE_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.average,
                        b.average,
                        isAsc
                    );
                case this.PRIMARY_AGGREGATE_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.primaryGroupAggregate,
                        b.primaryGroupAggregate,
                        isAsc
                    );
                case this.SECONDARY_AGGREGATE_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.secondaryGroupAggregate,
                        b.secondaryGroupAggregate,
                        isAsc
                    );
                case this.COMPARE_AMOUNT_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.comparedGroupAmount,
                        b.comparedGroupAmount,
                        isAsc
                    );    
                case this.COMPARE_COLUMN_ID:
                    return AggregateReportComponent.compare(
                        a.comparedGroupPercentage,
                        b.comparedGroupPercentage,
                        isAsc
                    );
                default: {
                    let aValue = a.dynamicFieldValuesByIds.get(fieldId);
                    let bValue = b.dynamicFieldValuesByIds.get(fieldId);
                    return AggregateReportComponent.compare(
                        aValue,
                        bValue,
                        isAsc
                    );
                }
            }
        });

        this.reportData = [...sortedData];
        this.downloadReportData = this.reportData;
        if (isSortCall) {
            this.saveSorting.emit();
        }
    }

    private convertDataToTableFormat(groupResults: ReportResultResponse[]) {
        this.reportData = [];
        this.downloadReportData = [];
        groupResults.forEach((groupResult) => {
            let percentage: number =
                this.totalAggregate > 0
                    ? (groupResult.values[0].result / this.totalAggregate) * 100
                    : 0;
            let tableEntry: ReportRow = {
                count: groupResult.values[0].count,
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    groupResult.buckets
                ),
                percentage: RandomUtils.roundUp(percentage * 100) / 100,
                aggregate: groupResult.values[0].result,
                average:
                    groupResult.values[0].result / groupResult.values[0].count,
            };
            this.downloadReportData.push(tableEntry);
        });
        this.reportData = this.downloadReportData.slice(0, this.LIMIT);
    }

    getDynamicFieldValuesByIds(
        groupResultBuckets: ReportResultGroupResponse[]
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        groupResultBuckets.forEach((groupResultBucket) => {
            let value = groupResultBucket.value
                ? groupResultBucket.value
                : "N/A";
            tableEntries.set(
                DatasetUtils.createUniqueIdentifierForDatasetField(
                    groupResultBucket.datasetID,
                    groupResultBucket.fieldID
                ),
                value
            );
        });
        return tableEntries;
    }

    /**
     * We need a structure like: Map<String, chartDataset[]>
     * The key if the main field value
     * The value is the chartDataset list for each of the other field's values (one chart dataset per second field value).
     * Each chart dataset array will contain the count values for the pair (mainField, secondField)
     */
    private populateChartData() {
        if (this.selectedBreakdownFieldsCount > 2) {
            return; // cannot create chart with more than 2 group by fields
        }
        if (this.reportData.length === 0) {
            return;
        }
        this.initializeChartData();

        let chartDatasetsInfo: ChartInfo[] = [];
        let mainDatasetFieldValues = []; // chart labels
        let chartDatasets: ChartDataSets[] = [];

        if (this.selectedBreakdownFieldsCount <= 1) {
            if (!this.isComparisonModeActivated) {
                let chartDataset = {
                    data: [],
                };
                let chartDatasetInfo = {
                    backgroundColor: [],
                    count: [],
                };
                chartDatasets.push(chartDataset);
                chartDatasetsInfo.push(chartDatasetInfo);

                this.reportData.forEach((entry) => {
                    mainDatasetFieldValues.push(
                        entry.dynamicFieldValuesByIds.values().next().value
                    );
                    chartDatasetInfo.backgroundColor.push(
                        ColorUtils.generateRandomHexColor()
                    );
                    chartDatasetInfo.count.push(entry.count);
                    switch (this.chartDisplayType) {
                        case ChartDisplayType.AGGREGATE:
                            chartDataset.data.push(entry.aggregate);
                            break;
                        case ChartDisplayType.COUNT:
                            chartDataset.data.push(entry.count);
                            break;
                        case ChartDisplayType.AVERAGE:
                            chartDataset.data.push(entry.average);
                            break;
                    }
                });
            } else {
                const primaryGroupValues = [];
                const secondaryGroupValues = [];
                const primaryGroupColor = ColorUtils.generateRandomHexColor();
                const secondaryGroupColor = ColorUtils.generateRandomHexColor();

                this.reportData.forEach((entry, index) => {
                    switch (this.chartDisplayType) {
                        case ChartDisplayType.AGGREGATE:
                            primaryGroupValues.push(
                                entry.primaryGroupAggregate
                            );
                            secondaryGroupValues.push(
                                entry.secondaryGroupAggregate
                            );
                            break;
                        case ChartDisplayType.COUNT:
                            primaryGroupValues.push(entry.primaryGroupCount);
                            secondaryGroupValues.push(
                                entry.secondaryGroupCount
                            );
                            break;
                        case ChartDisplayType.AVERAGE:
                            primaryGroupValues.push(entry.primaryGroupAverage);
                            secondaryGroupValues.push(
                                entry.secondaryGroupAverage
                            );
                            break;
                    }
                    mainDatasetFieldValues.push(
                        entry.dynamicFieldValuesByIds.values().next().value
                    );
                });

                chartDatasets = [
                    {
                        label: this.getGroupName(
                            AnayticsConstants.PRIMARY_GROUP_INDEX
                        ),
                        data: primaryGroupValues,
                        backgroundColor: primaryGroupColor,
                    },
                    {
                        label: this.getGroupName(
                            AnayticsConstants.SECONDARY_GROUP_INDEX
                        ),
                        data: secondaryGroupValues,
                        backgroundColor: secondaryGroupColor,
                    },
                ];
            }
        } else {
            let mainField = this.reportData[0].dynamicFieldValuesByIds
                .keys()
                .next().value; // we will use this as the main field in the chart
            this.reportData.forEach((row) => {
                let mainFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                if (!mainDatasetFieldValues.includes(mainFieldValue)) {
                    mainDatasetFieldValues.push(mainFieldValue);
                }
            });

            this.reportData.forEach((row) => {
                let mainDatasetFieldValue =
                    row.dynamicFieldValuesByIds.get(mainField);
                let indexInDatasetArray = mainDatasetFieldValues.indexOf(
                    mainDatasetFieldValue
                ); // because the labels indexes must match the values indexes
                row.dynamicFieldValuesByIds.forEach((value, datasetFieldId) => {
                    if (datasetFieldId !== mainField) {
                        let chartDataset = chartDatasets.find(
                            (cd) => cd.label === value
                        );
                        let chartDatasetInfo = chartDatasetsInfo.find(
                            (bg) => bg.label === value
                        );
                        if (!chartDataset) {
                            chartDataset = {
                                data: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                                label: value,
                            };
                            chartDatasets.push(chartDataset);
                        }
                        if (!chartDatasetInfo) {
                            chartDatasetInfo = {
                                label: value,
                                backgroundColor: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(ColorUtils.generateRandomHexColor()),
                                count: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                            };
                            chartDatasetsInfo.push(chartDatasetInfo);
                        }
                        switch (this.chartDisplayType) {
                            case ChartDisplayType.AGGREGATE:
                                chartDataset.data[indexInDatasetArray] =
                                    row.aggregate;
                                break;
                            case ChartDisplayType.COUNT:
                                chartDataset.data[indexInDatasetArray] =
                                    row.count;
                                break;
                            case ChartDisplayType.AVERAGE:
                                chartDataset.data[indexInDatasetArray] =
                                    row.average;
                                break;
                        }
                        chartDatasetInfo.count[indexInDatasetArray] = row.count;
                    }
                });
            });
        }

        this.chartDatasets = chartDatasets;
        this.chartLabels = mainDatasetFieldValues;
        this.chartColors = chartDatasetsInfo;
    }

    private initializeChartData() {
        this.chartDatasets = [];
        this.chartLabels = [];
        this.chartColors = [];
    }

    private prepareProjection() {
        if (!this.datapointProjection) {
            this.datapointProjection = {
                datasetID: this._dataset.id,
                fields: [],
                links: [],
            };
        }

        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            if (datasetId === this._dataset.id) {
                this.datapointProjection.fields = fields.map(
                    (field) => field.id
                );
            } else {
                let linkProjection: DatapointProjection = {
                    datasetID: datasetId,
                    fields: fields.map((field) => field.id),
                };
                this.datapointProjection.links.push(linkProjection);
            }
        });
        if (this.selectedAggregateField) {
            this.datapointProjection.fields.push(
                this.selectedAggregateField.id
            );
        }
    }

    onAggregateFieldSelected(fieldSelected: boolean, field: DatasetField) {
        if (fieldSelected) {
            this.selectedAggregateField = field;
            this._dataset.fields.forEach((f) => {
                f.selected = f.id === field.id;
            });
            this.selectedFormula = undefined;
            this._formulas.forEach((formula) => (formula.selected = false));
        } else {
            this.selectedAggregateField = undefined;
        }

        this.changeDetector.detectChanges();
    }

    onChartDisplayTypeChanged() {
        this.populateChartData();
    }

    datasetIsSelected(dataset: Dataset): boolean {
        // we need .foreach and not .get to do the comparison by id, in case the selected fields are set via setter
        let isSelected = false;
        this.selectedBreakdownFieldsByDataset.forEach((fields, dsId) => {
            if (dsId === dataset.id) {
                isSelected = true;
            }
        });
        return isSelected;
    }

    getTableReportHeader(): TableColumn[] {
        let columns: TableColumn[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });
        columns.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetField(
                this._dataset.id,
                this.selectedAggregateField.id
            ),
            type: TableColumnType.DECIMAL,
            name: this.selectedAggregateField.name,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        columns.push({
            id: this.PERCENTAGE_COLUMN_ID,
            name: "Total %",
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        columns.push({
            id: this.COUNT_COLUMN_ID,
            name: "Count",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        columns.push({
            id: this.AVERAGE_COLUMN_ID,
            name: "Average",
            type: TableColumnType.DECIMAL,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        return columns;
    }

    getTableReportFooter(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        this.dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces
        cells.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetField(
                this._dataset.id,
                this.selectedAggregateField.id
            ),
            value: RandomUtils.roundUp(this.totalAggregate),
        });
        cells.push({ id: this.PERCENTAGE_COLUMN_ID, value: "100%" });
        cells.push({
            id: this.COUNT_COLUMN_ID,
            value: this.totalCount.toString(),
        });
        cells.push({
            id: this.AVERAGE_COLUMN_ID,
            value: RandomUtils.roundUp(this.totalAggregate / this.totalCount),
        });

        return { cells: cells };
    }

    getTableReportRows(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            this.dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });
            columns.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetField(
                    this._dataset.id,
                    this.selectedAggregateField.id
                ),
                value: row.aggregate,
            });
            columns.push({
                id: this.PERCENTAGE_COLUMN_ID,
                value: row.percentage.toString() + "%",
            });
            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });
            columns.push({
                id: this.AVERAGE_COLUMN_ID,
                value: RandomUtils.roundUp(row.average),
            });
            rows.push({ cells: columns });
        });

        return rows;
    }

    getTableReportDownloadRequest(): DownloadReportItem {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeader();
            let reportRows = this.getTableReportRows();
            let reportFooter = this.getTableReportFooter();
            let title = this.reportName || "Aggregate";

            return new DownloadReportTableRequest(
                title,
                reportHeader,
                reportRows,
                reportFooter
            );
        } else {
            return null;
        }
    }

    getChartReportDownloadRequest(): DownloadReportItem {
        let breakdownFieldsNames: string[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: this.reportName || "Aggregate",
            valueKey:
                (this.chartDisplayType === ChartDisplayType.AGGREGATE &&
                    ValueKey.VALUE) ||
                (this.chartDisplayType === ChartDisplayType.COUNT &&
                    ValueKey.COUNT) ||
                (this.chartDisplayType === ChartDisplayType.AVERAGE &&
                    ValueKey.AVERAGE),
            type: undefined,
            columns: {
                value: this.chartDisplayType,
                categories: breakdownFieldsNames,
            },
            values: [],
        };

        this.chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                const count = this.chartColors[datasetIndex].count[valueIndex];
                const chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [this.chartLabels[valueIndex]],
                    colors: [
                        this.chartColors[datasetIndex].backgroundColor[
                            valueIndex
                        ],
                    ],
                    count: count,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        if (this.reportSubType === ReportDisplayType.PIE_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;
        } else if (this.reportSubType === ReportDisplayType.BAR_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_BAR_CHART;
        }

        return request;
    }

    getReportDownloadRequest(): DownloadReportItem {
        if (this.getDisplayType() === ReportDisplayType.TABLE) {
            return !this.isComparisonModeActivated
                ? this.getTableReportDownloadRequest()
                : this.getTableReportDownloadRequestForComparison();
        } else if (
            this.getDisplayType() === ReportDisplayType.BAR_CHART ||
            this.getDisplayType() === ReportDisplayType.PIE_CHART
        ) {
            return !this.isComparisonModeActivated
                ? this.getChartReportDownloadRequest()
                : this.getChartReportDownloadRequestForComparison();
        }
    }

    getDisplayType(): ReportDisplayType {
        return this.reportSubType;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    isTwoDimensionReport(): boolean {
        let selectedFieldNo = 0;
        this.selectedBreakdownFieldsByDataset.forEach(
            (fields, datasetId) => (selectedFieldNo += fields.length)
        );
        return selectedFieldNo === 2;
    }

    onFormulaSelected(fieldSelected: boolean, selectedFormula: WorkspaceItem) {
        if (fieldSelected) {
            this._formulas.forEach((formula) => {
                formula.selected = formula.id === selectedFormula.id;
            });
            this.selectedFormula = selectedFormula;
            this.selectedAggregateField = undefined;
            this.datasetFields.forEach((f) => (f.selected = false));
        } else {
            this.selectedFormula = undefined;
            selectedFormula.selected = false;
            this.selectedAggregateField = undefined;
        }
        this.changeDetector.detectChanges();
    }

    private populateAggregateFieldColumnName() {
        if (this.selectedFormula) {
            this.selectedAggregateName = this.selectedFormula.name;
        } else {
            this.selectedAggregateName = this.selectedAggregateField.name;
        }
    }

    sortFields(fields: DatasetField[]) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            }
        });
        return fields;
    }

    resetNRISelectedFlag() {
        this._nriFields.forEach((outerElement) => {
            outerElement.child.forEach((element) => {
                element.child.selected = false;
            });
        });
    }

    getSaveButtonLabel() {
        return this.isDashboardCall ? "Save" : "Save to Dashboard";
    }

    getSaveAsButtonLabel() {
        return this.isDashboardCall ? "Save as" : "Save as Dashboard";
    }

    getDisplayName(node) {
        return this.datapointObject.getDisplayName(node);
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }
    getDisabledTreeNode(node) {
        if (!isUndefined(node.params)) {
            const field = node.params.field;
            if (node.params.callType == "dataset") {
                return (
                    (this.selectedAggregateField &&
                        this.selectedAggregateField?.id !== field.id) ||
                    this.selectedFormula !== undefined
                );
            } else if (node.params.callType == "formula") {
                return (
                    (this.selectedFormula &&
                        this.selectedFormula.id !== field.id) ||
                    this.selectedAggregateField !== undefined
                );
            } else {
                return false;
            }
        }
    }
    onAggregateFieldModelChangeEvent(node) {
        if (!isUndefined(node.params)) {
            const field = node.params.field;
            if (node.params.callType == "dataset") {
                this.onAggregateFieldSelected(
                    node.selected,
                    node.params?.field
                );
            } else if (node.params.callType == "formula") {
                this.onFormulaSelected(node.selected, node.params?.field);
            }
        }
    }

    collapseAll(): void {
        this.aggregateTreeControl.collapseAll();
        this.breakdownTreeControl.collapseAll();
    }

    isLocationTypeApplication() {
        return this._dataset.application === MaptycsApplication.LOCATIONS;
    }

    setFieldsSelected(globalOverlays): GroupWithOverlaysTreeNode[] {
        for (const [key, value] of this.selectedBreakdownFieldsByDataset) {
            this.globalOverlays.forEach((dataset) => {
                if (dataset?.overlays?.length) {
                    let selectedOverlay = this.findSelectedOverlay(
                        dataset.overlays,
                        key
                    );

                    if (!selectedOverlay) {
                        selectedOverlay = this.findSelectedOverlayFromChildren(
                            dataset.children,
                            key
                        );
                    }

                    if (selectedOverlay?.fields) {
                        for (const field of value) {
                            let matchingField = selectedOverlay.fields.find(
                                (overlayField) => overlayField.id === field.id
                            );
                            if (matchingField) {
                                this.datapointObject.markSelected(
                                    this.breakdownDataSource.data,
                                    matchingField.id
                                );
                                matchingField.selected = true;
                            }
                        }
                    }
                }
            });
        }
        return globalOverlays;
    }

    findSelectedOverlay(overlays, key) {
        const selectedOverlay = overlays.find((overlay) => overlay.id === key);

        return selectedOverlay;
    }

    findSelectedOverlayFromChildren(
        children: GroupWithOverlaysTreeNode[],
        key: string
    ): any {
        for (const element of children || []) {
            const foundOverlay = this.findSelectedOverlay(
                element.overlays,
                key
            );

            if (foundOverlay) {
                return foundOverlay;
            }

            if (element.children && element.children.length > 0) {
                const overlayFromChildren =
                    this.findSelectedOverlayFromChildren(element.children, key);
                if (overlayFromChildren) {
                    return overlayFromChildren;
                }
            }
        }

        return null;
    }

    // Comparison code
    getSelectedGroups() {
        return [...AnalyticsUtils.getSelectedNodes(this._compareGroupsStrcuture), ...AnalyticsUtils.getSelectedNodes(this._withGroupsStrcuture)];
    }

    getActiveGroups() {
        return this.activeGroups;
    }

    findGroupCount(groupIndex, result) {
        const groupId = this.getActiveGroups()[groupIndex].id;
        const bucketIndex = result.buckets.findIndex(
            (bucket) => groupId === bucket.groupID
        );

        if (bucketIndex !== -1) {
            return result.values[bucketIndex].count || 0;
        }

        return 0;
    }

    findGroupResult(groupIndex, result) {
        const groupId = this.getActiveGroups()[groupIndex].id;
        const bucketIndex = result.buckets.findIndex(
            (bucket) => groupId === bucket.groupID
        );

        if (bucketIndex !== -1) {
            return result.values[bucketIndex].result || 0;
        }

        return 0;
    }

    private computeTotalCountAndAggregateForComparison(
        groupResults: ReportResultResponse[]
    ) {
        this.totalCount = 0;
        this.totalAggregate = 0;

        groupResults.forEach((result) => {
            this.totalCount +=
                this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ) +
                this.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                );

            this.totalAggregate +=
                this.findGroupResult(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ) +
                this.findGroupResult(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                );
        });

        this.totalCountPrimaryGroup = 0;
        groupResults.forEach((result) => {
            this.totalCountPrimaryGroup += this.findGroupCount(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                result
            );
        });

        this.totalCountSecondaryGroup = 0;
        groupResults.forEach((result) => {
            this.totalCountSecondaryGroup += this.findGroupCount(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                result
            );
        });

        this.totalAggregatePrimaryGroup = 0;
        groupResults.forEach((result) => {
            this.totalAggregatePrimaryGroup += this.findGroupResult(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                result
            );
        });

        this.totalAggregateSecondaryGroup = 0;
        groupResults.forEach((result) => {
            this.totalAggregateSecondaryGroup += this.findGroupResult(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                result
            );
        });

        this.totalAggregate =
            RandomUtils.roundUp(this.totalAggregate * 100) / 100;
        this.totalAggregatePrimaryGroup =
            RandomUtils.roundUp(this.totalAggregatePrimaryGroup * 100) / 100;
        this.totalAggregateSecondaryGroup =
            RandomUtils.roundUp(this.totalAggregateSecondaryGroup * 100) / 100;
    }

    private calculatePercentage(count: number, totalCount: number): number {
        return totalCount > 0 ? (count / totalCount) * 100 : 0;
    }

    calculateTotalAverage(result) {
        const totalCount =
            this.findGroupCount(AnayticsConstants.PRIMARY_GROUP_INDEX, result) +
            this.findGroupCount(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                result
            );
        const totalAggregate =
            this.findGroupResult(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                result
            ) +
            this.findGroupResult(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                result
            );

        if (totalCount) {
            return totalAggregate / totalCount;
        }

        return 0;
    }

    private convertDataToTableFormatForComparison(
        groupResults: ReportResultResponse[]
    ) {
        this.reportData = [];
        this.downloadReportData = [];
        groupResults.forEach((result) => {
            const percentage: number = this.calculatePercentage(
                this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ) +
                    this.findGroupCount(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result
                    ),
                this.totalCount
            );
            const primaryGroupPercentage: number = this.calculatePercentage(
                this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ),
                this.totalCountPrimaryGroup
            );
            const secondaryGroupPercentage: number = this.calculatePercentage(
                this.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                ),
                this.totalCountSecondaryGroup
            );
            const comparedGroupPercentage: number = AnalyticsUtils.calculateVariancePercentage(
                this.findGroupResult(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ),
                this.findGroupResult(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                )
            );
            

            let tableEntry: ReportRow = {
                primaryGroupCount: this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ),
                secondaryGroupCount: this.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                ),
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    result.buckets
                ),
                primaryGroupPercentage: RandomUtils.roundUp(
                    primaryGroupPercentage
                ),
                secondaryGroupPercentage: RandomUtils.roundUp(
                    secondaryGroupPercentage
                ),
                comparedGroupAmount:
                this.findGroupResult(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ) -
                this.findGroupResult(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                ),
                comparedGroupPercentage: RandomUtils.roundUp(
                    comparedGroupPercentage
                ),
                primaryGroupAggregate: this.findGroupResult(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ),
                secondaryGroupAggregate: this.findGroupResult(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                ),
                primaryGroupAverage: this.calculateAverage(
                    this.findGroupResult(
                        AnayticsConstants.PRIMARY_GROUP_INDEX,
                        result
                    ),
                    this.findGroupCount(
                        AnayticsConstants.PRIMARY_GROUP_INDEX,
                        result
                    )
                ),
                secondaryGroupAverage: this.calculateAverage(
                    this.findGroupResult(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result
                    ),
                    this.findGroupCount(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result
                    )
                ),
                average: this.calculateTotalAverage(result),
            };
            this.downloadReportData.push(tableEntry);
        });
        this.reportData = this.downloadReportData.slice(0, this.LIMIT);
    }

    calculateAverage(result, count) {
        if (count) {
            return result / count;
        }

        return 0;
    }

    isGenerateReportPossible() {
        if (this.isComparisonModeActivated) {
            return (
                this.getSelectedGroups().length ===
                    AnayticsConstants.MAX_GROUP_SELECT_LIMIT &&
                (this.selectedAggregateField || this.selectedFormula) &&
                this.selectedBreakdownFieldsByDataset.size
            );
        } else {
            return (
                (this.selectedAggregateField || this.selectedFormula) &&
                this.selectedBreakdownFieldsByDataset.size > 0
            );
        }
    }

    private populateTableColumnsListForComparison() {
        this.dynamicColumns = new Map();
        const dynamicColumnsAsArray = [];

        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                this.dynamicColumns.set(key, field.name);
                dynamicColumnsAsArray.push(key);
            });
        });

        this.columnsToDisplayForComparison = [
            ...dynamicColumnsAsArray,
            this.PRIMARY_AGGREGATE_COLUMN_ID,
            this.SECONDARY_AGGREGATE_COLUMN_ID,
            this.COMPARE_AMOUNT_COLUMN_ID,
            this.COMPARE_COLUMN_ID,
        ];
    }

    getGroupName(groupIndex, isLengthChecked: boolean = true) {
        return AnalyticsUtils.getGroupName(groupIndex, this.getActiveGroups(), isLengthChecked);
    }

    // Download comparison
    getTableReportFooterForComparison(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        this.dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1);

        cells.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetFieldForGroup(
                this._dataset.id,
                this.selectedAggregateField.id,
                AnayticsConstants.PRIMARY_GROUP_INDEX
            ),
            value: RandomUtils.roundUp(this.totalAggregatePrimaryGroup),
        });

        cells.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetFieldForGroup(
                this._dataset.id,
                this.selectedAggregateField.id,
                AnayticsConstants.SECONDARY_GROUP_INDEX
            ),
            value: RandomUtils.roundUp(this.totalAggregateSecondaryGroup),
        });
        cells.push({ id: this.COMPARE_AMOUNT_COLUMN_ID, value:  RandomUtils.roundUp((this.totalAggregatePrimaryGroup - this.totalAggregateSecondaryGroup)) });
        cells.push({ id: this.COMPARE_COLUMN_ID, value: RandomUtils.roundUp(AnalyticsUtils.calculateVariancePercentage(this.totalAggregatePrimaryGroup, this.totalAggregateSecondaryGroup)) + "%" });
        return { cells: cells };
    }

    getTableReportRowsForComparison(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            this.dynamicColumns.forEach((_value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });

            columns.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetFieldForGroup(
                    this._dataset.id,
                    this.selectedAggregateField.id,
                    AnayticsConstants.PRIMARY_GROUP_INDEX
                ),
                value: row.primaryGroupAggregate,
            });

            columns.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetFieldForGroup(
                    this._dataset.id,
                    this.selectedAggregateField.id,
                    AnayticsConstants.SECONDARY_GROUP_INDEX
                ),
                value: row.secondaryGroupAggregate,
            });
            columns.push({
                id: this.COMPARE_AMOUNT_COLUMN_ID,
                value: row.comparedGroupAmount.toString(),
            });
            columns.push({
                id: this.COMPARE_COLUMN_ID,
                value: row.comparedGroupPercentage.toString() + "%",
            });

            rows.push({ cells: columns });
        });

        return rows;
    }

    getTableReportHeaderForComparison(): TableColumn[] {
        let columns: TableColumn[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });

        columns.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetFieldForGroup(
                this._dataset.id,
                this.selectedAggregateField.id,
                AnayticsConstants.PRIMARY_GROUP_INDEX
            ),
            type: TableColumnType.DECIMAL,
            name: `${this.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX, false
            )} ${this.selectedAggregateField.name}`,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });

        columns.push({
            id: DatasetUtils.createUniqueIdentifierForDatasetFieldForGroup(
                this._dataset.id,
                this.selectedAggregateField.id,
                AnayticsConstants.SECONDARY_GROUP_INDEX
            ),
            type: TableColumnType.DECIMAL,
            name: `${this.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX, false
            )} ${this.selectedAggregateField.name}`,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });

        columns.push({
            id: this.COMPARE_AMOUNT_COLUMN_ID,
            name: `${this.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX, false
            )} vs ${this.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX, false
            )}`,
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });

        columns.push({
            id: this.COMPARE_COLUMN_ID,
            name: `${this.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX, false
            )} vs ${this.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX, false
            )} (%)`,
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });

        return columns;
    }

    getTableReportDownloadRequestForComparison(): DownloadReportItem {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeaderForComparison();
            let reportRows = this.getTableReportRowsForComparison();
            let reportFooter = this.getTableReportFooterForComparison();
            let title = this.reportName || "Aggregate";

            return new DownloadReportTableRequest(
                title,
                reportHeader,
                reportRows,
                reportFooter
            );
        } else {
            return null;
        }
    }

    getChartReportDownloadRequestForComparison(): DownloadReportItem {
        let breakdownFieldsNames: string[] = [];
        this.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: this.reportName || "Aggregate",
            valueKey:
                (this.chartDisplayType === ChartDisplayType.AGGREGATE &&
                    ValueKey.VALUE) ||
                (this.chartDisplayType === ChartDisplayType.COUNT &&
                    ValueKey.COUNT) ||
                (this.chartDisplayType === ChartDisplayType.AVERAGE &&
                    ValueKey.AVERAGE),
            type: undefined,
            columns: {
                value: this.chartDisplayType,
                categories: [...breakdownFieldsNames, "Aggreagate"],
            },
            values: [],
        };

        this.chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                const chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [this.chartLabels[valueIndex]],
                    colors: [dataset.backgroundColor.toString()],
                    count: value,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        if (this.reportSubType === ReportDisplayType.PIE_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;
        } else if (this.reportSubType === ReportDisplayType.BAR_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_BAR_CHART;
        }

        return request;
    }

    onCheckboxChange(event: MatCheckboxChange, type: string) {
        const result = AnalyticsUtils.groupSelectionEvent(event, type, this._compareGroups, this._withGroups);
        this._compareGroups = result.compareGroups;
        this._withGroups = result.withGroups;
    }

    toggleItemExpansion(item: TreeNode) {
        item.expanded = !item.expanded;
    }

    toggleItem(event: MatCheckboxChange, item: TreeNode, section: 'compare' | 'with') {
        if(event.checked && section == 'compare') {
            this.compareGroupCheckEvent(this._withGroupsStrcuture, item.id, 'with');
            this.compareGroupCheckEvent(this._compareGroupsStrcuture, item.id, 'compare');
        } else if(event.checked && section == 'with') {
            this.withGroupCheckEvent(this._compareGroupsStrcuture, item.id, 'compare');
            this.withGroupCheckEvent(this._withGroupsStrcuture, item.id, 'with');
        } else if(!event.checked && section == 'compare') {
            let selectedItem = AnalyticsUtils.getSelectedNodes(this._withGroupsStrcuture);
            const withItem = selectedItem.length > 0 ? selectedItem[0] : undefined;
            this.compareGroupUnCheckEvent(this._withGroupsStrcuture, item.id, 'with', withItem);
            this.compareGroupUnCheckEvent(this._compareGroupsStrcuture, item.id, 'compare', withItem);
        } else if(!event.checked && section == 'with') {
            let selectedItem = AnalyticsUtils.getSelectedNodes(this._compareGroupsStrcuture);
            const compareItem = selectedItem.length > 0 ? selectedItem[0] : undefined;
            this.withGroupUnCheckEvent(this._compareGroupsStrcuture, item.id, 'compare', compareItem);
            this.withGroupUnCheckEvent(this._withGroupsStrcuture, item.id, 'with', compareItem);
        }  
    }

    compareGroupCheckEvent(nodes: TreeNode[], id: number, section: string) {
        for (const node of nodes) {
            if ((section === 'compare' && node.id !== id) || (section === 'with' && node.id == id)) {
                node.disabled = true;
            } 
            if (node.children) {
                this.compareGroupCheckEvent(node.children, id, section);
            }
        }
    }

    withGroupCheckEvent(nodes: TreeNode[], id: number, section: string) {
        for (const node of nodes) {
            if ((section === 'compare' && node.id === id) || (section === 'with' && node.id !== id)) {
                node.disabled = true;
            } 
            if (node.children) {
                this.withGroupCheckEvent(node.children, id, section);
            }
        }
    }

    compareGroupUnCheckEvent(nodes: TreeNode[], id: number, section: string, withItem: any) {
        for (const node of nodes) {
            if ((section === 'with' && node.id == id) && withItem == undefined) {
                node.disabled = false;
            } else if (section === 'compare' && (withItem !== undefined && (node.id !== withItem.id && node.id !== id) ||  withItem == undefined)) {
                node.disabled = false;
            } else if (section === 'compare' && withItem == undefined) {
                node.disabled = false;
            }
            if (node.children) {
                this.compareGroupUnCheckEvent(node.children, id, section, withItem);
            }
        }
    }

    withGroupUnCheckEvent(nodes: TreeNode[], id: number, section: string, compareItem: any) {
        for (const node of nodes) {
            if ((section === 'compare' && node.id == id) && compareItem == undefined) {
                node.disabled = false;
            } else if (section === 'with' && (compareItem !== undefined && (node.id !== compareItem.id && node.id !== id) ||  compareItem == undefined)) {
                node.disabled = false;
            } else if (section === 'with' && compareItem == undefined) {
                node.disabled = false;
            }
            if (node.children) {
                this.withGroupUnCheckEvent(node.children, id, section, compareItem);
            }
        }
    }
}
